
import { mapGetters } from 'vuex'
import ProductsCard from '~/components/ProductsCard'
export default {
  name: 'TabBar',
  components: {
    ProductsCard
  },
  computed: {
    ...mapGetters({
      cartItemList: 'cart/cartItemList'
    })
  }
}
