
// const date = new Date()
export default {
  name: 'Foot',
  data () {
    return {
      date: new Date()
    }
  }
}
