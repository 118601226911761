export const AttributesCheckbox = () => import('../../components/AttributesCheckbox.vue' /* webpackChunkName: "components/attributes-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumbs = () => import('../../components/BreadCrumbs.vue' /* webpackChunkName: "components/bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const CategoriesGrid = () => import('../../components/CategoriesGrid.vue' /* webpackChunkName: "components/categories-grid" */).then(c => wrapFunctional(c.default || c))
export const CategorySlide = () => import('../../components/CategorySlide.vue' /* webpackChunkName: "components/category-slide" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const Foot = () => import('../../components/Foot.vue' /* webpackChunkName: "components/foot" */).then(c => wrapFunctional(c.default || c))
export const IntSlides = () => import('../../components/IntSlides.vue' /* webpackChunkName: "components/int-slides" */).then(c => wrapFunctional(c.default || c))
export const ItemSwipe = () => import('../../components/ItemSwipe.vue' /* webpackChunkName: "components/item-swipe" */).then(c => wrapFunctional(c.default || c))
export const ModalProducts = () => import('../../components/ModalProducts.vue' /* webpackChunkName: "components/modal-products" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailsPage = () => import('../../components/ProductDetailsPage.vue' /* webpackChunkName: "components/product-details-page" */).then(c => wrapFunctional(c.default || c))
export const ProductsCard = () => import('../../components/ProductsCard.vue' /* webpackChunkName: "components/products-card" */).then(c => wrapFunctional(c.default || c))
export const ProductsOrderView = () => import('../../components/ProductsOrderView.vue' /* webpackChunkName: "components/products-order-view" */).then(c => wrapFunctional(c.default || c))
export const ProductsPage = () => import('../../components/ProductsPage.vue' /* webpackChunkName: "components/products-page" */).then(c => wrapFunctional(c.default || c))
export const SlideShow = () => import('../../components/SlideShow.vue' /* webpackChunkName: "components/slide-show" */).then(c => wrapFunctional(c.default || c))
export const Slides = () => import('../../components/Slides.vue' /* webpackChunkName: "components/slides" */).then(c => wrapFunctional(c.default || c))
export const TabBar = () => import('../../components/TabBar.vue' /* webpackChunkName: "components/tab-bar" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
