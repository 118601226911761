export default function ({ $axios, redirect }) {
  // $axios.onRequest(config => {
  //   console.log('Making request to ' + config.url)
  // })

  $axios.onError((error) => {
    if (error.response.status === 401 && error.response.data.message === 'Unauthenticated.') {
      redirect('/signin?force=true')
    }
  })
}
