import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f913a95e = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _79b08e13 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _12d68acf = () => interopDefault(import('../pages/categories.vue' /* webpackChunkName: "pages/categories" */))
const _799132e2 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _6fa9d5ca = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _5e1273cc = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _668ac6d5 = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _7b211d4b = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _cbbcbb7a = () => interopDefault(import('../pages/orders/_id.vue' /* webpackChunkName: "pages/orders/_id" */))
const _a1ade630 = () => interopDefault(import('../pages/products/_category/index.vue' /* webpackChunkName: "pages/products/_category/index" */))
const _f39f8be6 = () => interopDefault(import('../pages/reset-password/_token.vue' /* webpackChunkName: "pages/reset-password/_token" */))
const _1f6173d3 = () => interopDefault(import('../pages/products/_category/_id/index.vue' /* webpackChunkName: "pages/products/_category/_id/index" */))
const _3ee0983f = () => interopDefault(import('../pages/products/_category/_id/_product.vue' /* webpackChunkName: "pages/products/_category/_id/_product" */))
const _fba58522 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _f913a95e,
    name: "about-us"
  }, {
    path: "/cart",
    component: _79b08e13,
    name: "cart"
  }, {
    path: "/categories",
    component: _12d68acf,
    name: "categories"
  }, {
    path: "/forgot-password",
    component: _799132e2,
    name: "forgot-password"
  }, {
    path: "/orders",
    component: _6fa9d5ca,
    name: "orders"
  }, {
    path: "/products",
    component: _5e1273cc,
    name: "products"
  }, {
    path: "/signin",
    component: _668ac6d5,
    name: "signin"
  }, {
    path: "/signup",
    component: _7b211d4b,
    name: "signup"
  }, {
    path: "/orders/:id",
    component: _cbbcbb7a,
    name: "orders-id"
  }, {
    path: "/products/:category",
    component: _a1ade630,
    name: "products-category"
  }, {
    path: "/reset-password/:token?",
    component: _f39f8be6,
    name: "reset-password-token"
  }, {
    path: "/products/:category/:id",
    component: _1f6173d3,
    name: "products-category-id"
  }, {
    path: "/products/:category/:id/:product",
    component: _3ee0983f,
    name: "products-category-id-product"
  }, {
    path: "/",
    component: _fba58522,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
