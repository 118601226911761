import anime from 'animejs'
import Vue from 'vue'

// function numberRandom (min, max) {
//   return Math.round(Math.random() * (max - min) + min)
// }
// window.addEventListener('mousemove', function (evt) {
//   const x = evt.x
//   const y = evt.y
//   const cursor = document.querySelector('.cursor')
//   const cursorPoint = document.querySelector('.cursor-point')
//   setTimeout(() => {
//     cursor.style.left = x + 'px'
//     cursor.style.top = y + 'px'
//   }, 60)
//   setTimeout(() => {
//     cursorPoint.style.left = x + 'px'
//     cursorPoint.style.top = y + 'px'
//   }, 60)
// })
// window.addEventListener('mousedown', function (evt) {
//   const cursor = document.querySelector('.cursor')
//   const cursorPoint = document.querySelector('.cursor-point')
//   cursor.classList.add('click')
//   cursorPoint.classList.add('click')
//   const shadow = document.createElement('div')
//   shadow.className = 'shadow'
//   const number = numberRandom(80, 200)
//   cursor.appendChild(shadow)
//   setTimeout(() => {
//     shadow.style.width = number + 'px'
//     shadow.style.height = number + 'px'
//     shadow.classList.add('active')
//   }, 10)
//   setTimeout(() => {
//     cursor.removeChild(shadow)
//   }, 600)
// })
// window.addEventListener('mouseup', function (evt) {
//   const cursor = document.querySelector('.cursor')
//   const cursorPoint = document.querySelector('.cursor-point')
//   cursor.classList.remove('click')
//   cursorPoint.classList.remove('click')
// })

const buttons = document.querySelectorAll('.btn')
buttons.forEach((btn) => {
  btn.addEventListener('click', function (e) {
    const x = e.clientX - e.target.offsetLeft
    // const y = e.clientY - e.target.offsetTop

    const ripples = document.createElement('span')
    ripples.style.left = `${x}px`
    // ripples.style.top = `${y}px`
    this.appendChild(ripples)

    setTimeout(() => {
      ripples.remove()
    }, 1000)
  })
})

export const moneyFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

// function passURI (uri) {
//   return uri.toLowerCase().replace(/ /g, "-")
// }

// const menuBtn = document.querySelector('#mob-menu-btn')
// menuBtn.addEventListener('click', function (e) {
//   const menu = document.querySelector('.navbar')
//   const menuClass = menu.className.split(' ')
//   const className = 'is-active'
//   if (menuClass.includes(className)) {
//     menu.classList.remove(className)
//   } else {
//     menu.classList.add(className)
//   }
// })

// function createNotification () {
//   const noti = document.createElement('div')
//   const h3 = document.createElement('h3')
//   const p = document.createElement('p')
//   h3.innerHTML = 'Welcome to our tutorial'
//   p.innerHTML = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea repudiandae nihil'
//   noti.className = 'notification'
//   noti.appendChild(h3)
//   noti.appendChild(p)
//   document.body.appendChild(noti)
//   removeNotifications()
//   setTimeout(() => {
//     noti.classList.add('visible')
//   }, 10)

//   setTimeout(() => {
//     noti.classList.add('remove')
//     setTimeout(() => {
//       noti.remove()
//     }, 300)
//   }, 2500)
// }

// function removeNotifications () {
//   const notifications = document.querySelectorAll('.visible')
//   if (notifications.length > 0) {
//     notifications.forEach((noti) => {
//       noti.classList.add('remove')
//       setTimeout(() => {
//         noti.remove()
//       }, 300)
//     })
//   }
// }

// slideShow.addEventListener('resize', function (evt) {
//   console.log(slideShow)
// const slideShowImgs = document.querySelectorAll('.img-cont')
// slideShowImgs.forEach((btn) => {
// })
// })
// function updateSlideShow () {
//   const slideShow = document.querySelector('.slideshow')
//   const slideShowImgs = document.querySelectorAll('.img-cont')
//   slideShowImgs.forEach((img) => {
//     img.style.width = `${slideShow.offsetWidth}px`
//   })
// }
// window.addEventListener('resize', function (evt) {
//   updateSlideShow()
// })
// window.addEventListener('scroll', function (evt) {
//   const navBar = document.querySelector('.navbar')
//   // const body = document.querySelector('body')
//   if (window.innerWidth > 500) {
//     if (window.pageYOffset > 0) {
//       navBar.classList.add('navBg')
//       // body.style.background = '#fff'
//     } else {
//       navBar.classList.remove('navBg')
//       // body.style.background = ''
//     }
//   }
// })

// function handleStart (evt) {
//   evt.target.dataset.x = Number(evt.touches[0].pageX) + Number(evt.target.dataset.move || 0)
// }
// function handleTouchMove (evt) {
//   let moveX = Number(evt.target.dataset.x) - evt.touches[0].pageX
//   if (moveX > 130) {
//       moveX = 130
//   }
//   if (moveX < -130) {
//       moveX = -130
//   }
//   evt.target.dataset.move = moveX
//   anime({
//       targets: evt.target,
//       translateX: -Number(evt.target.dataset.move),
//       duration: 300
//   })
// }
// function handleEnd (evt) {
//   if (evt.target.dataset.move > 100) {
//   evt.target.dataset.move = 100
//   } else if (evt.target.dataset.move < -100) {
//   evt.target.dataset.move = -100
//   } else {
//   evt.target.dataset.move = 0
//   }
//   const swipes = document.querySelectorAll('.swipe') || []

//   swipes.forEach((item) => {
//       if (item.querySelector('.con-text') === evt.target) {
//           return
//       }
//       item.querySelector('.con-text').dataset.move = 0
//       item.querySelector('.con-text').dataset.x = 0
//       anime({
//           targets: item.querySelector('.con-text'),
//           translateX: 0
//       })
//   })
//   setTimeout(() => {
//       anime({
//           targets: evt.target,
//       translateX: -Number(evt.target.dataset.move)
//       })
//   }, 1)
// }

Object.defineProperty(Vue.prototype, '$anime', { value: anime })
