
// import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
export default {
  name: 'NavBar',
  data () {
    return {
      timeout: null,
      dropdown: false,
      navBg: false,
      loading: false,
      route: '',
      search: '',
      avoid: ['about-us', 'contact-us', 'products', 'products-category', 'products-category-product', 'signin', 'signup', 'forgot-password', 'reset-password-token', 'checkout']
    }
  },
  computed: {
    ...mapGetters({
      cartItemList: 'cart/cartItemList'
    })
  },
  methods: {
    logout () {
      this.$auth.$storage.setCookie('user', null)
      this.$notify.show({
        text: 'Successfully logout. Will be redirected',
        type: 'success',
        closeWait: 3000
      })
      setTimeout(() => {
        this.$router.push({ name: 'index' })
      }, 2000)
      this.$auth.logout()
      // this.$auth.setToken('local', false)
      // this.$auth.setRefreshToken(false)
      // this.$axios.setHeader('Authorization', false)
      // this.$auth.$storage.setState('loggedIn', false)
      // this.$auth.$storage.setUniversal('loggedIn', false)
      // this.$auth.$storage.setUniversal('user', false)
      // this.activeElement('.navbar')
      // this.$router.push({ name: 'signin' })
    },
    searchProduct () {
      this.loading = true
      if (this.timeout) { clearTimeout(this.timeout) }
      this.timeout = setTimeout(() => {
        this.searchx()
      }, 3000)
    },
    searchx () {
      this.activeElement('.searchInput')
      this.loading = false
      this.$router.push({
        name: 'products',
        params: {
          search: this.search
        }
      })
      this.search = ''
    },
    activeElement (element) {
      if (window.innerWidth < 500) {
        const elm = this.$el.querySelector(element)
        const elmClass = elm.className.split(' ')
        const className = 'is-active'
        if (elmClass.includes(className)) {
          elm.classList.remove(className)
        } else {
          elm.classList.add(className)
        }
      }
    }
  }
}
