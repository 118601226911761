
import notify from '~/plugins/notify/notify'

export default {
  data: () => ({
    options: {
      text: '',
      type: '',
      closeWait: 3000
    },
    show: false,
    type: '',
    timer: 0
  }),
  beforeMount () {
    notify.event.$on('show', (options) => {
      this.options = options
      this.type = options.type
      this.show = true
      this.close(this.options.closeWait || 3000)
    })
  },
  methods: {
    close (timeout) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.show = false
      }, timeout)
    }
  }
}
